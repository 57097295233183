<template>
    <Details
        title="integration partner"
        routeIdParam="partnerId"
        :base-path="basePath"
        data-provider="$partnerDataProvider"
        resource="communityPartners"
        :request-params="{communityId, headers}"
    >
        <template v-slot:default="detailsProps">
            <CustomerIntegrationsForm
                :initialValues="getInitialValues(detailsProps.record)"
                :on-submit="detailsProps.handleSubmit"
                :loading="detailsProps.loading"
                @close="detailsProps.redirectToList"
            />
        </template>
    </Details>
</template>

<script>
import CustomerIntegrationsForm from '@/views/auth/customers/communities/Integrations/CustomerIntegrationsForm';
import Details from '@/components/auth/details/Details';
import ModalNavigation from '@/mixins/ModalNavigation';

export default {
    name: "CustomerIntegrationsEdit",
    components: {CustomerIntegrationsForm, Details},
    mixins: [ModalNavigation],
    computed: {
        communityId() {
            return this.$route.params.communityId;
        },
        customerId() {
            return this.$route.params.customerId;
        },
        partnerId() {
            return this.$route.params.partnerId;
        },
        basePath: function () {
            return this.$route.name.replace('edit', 'index');
        },
        headers() {
            return {
                'X-Quext-Customer': this.customerId,
                'x-quext-customer-id': this.customerId,
            }
        },
    },
    methods: {
        getInitialValues({uuid: partnerId, fieldValues, purposes}) {
            return {
                partnerId,
                fieldValues: fieldValues
                    ? fieldValues.reduce((fieldValues, {keyName, keyValue}) => {
                        fieldValues[keyName] = keyValue;
                        return fieldValues;
                    }, {})
                    : {},
                purposes: purposes
                    ? purposes.reduce((purposes, {uuid}) => {
                        purposes[uuid] = true;
                        return purposes;
                    }, {})
                    : {},
            };
        },
    },
}
</script>
